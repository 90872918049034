import React, { useState, useEffect, useRef } from 'react'
import { motion, AnimatePresence, useAnimation } from 'framer-motion'
import { User, Code, Briefcase, Star, MessageCircle, Github, Music, Sun, Moon } from 'lucide-react'

const themeConfig = {
  default: {
    bgGradient: 'from-gray-900 to-gray-800',
    textColor: 'text-white',
    cardBg: 'bg-gray-800',
    buttonBg: 'bg-blue-600',
    buttonHoverBg: 'hover:bg-blue-700',
    music: '/music/default-theme.mp3'
  },
  sad: {
    bgGradient: 'from-blue-900 to-gray-900',
    textColor: 'text-gray-300',
    cardBg: 'bg-gray-900',
    buttonBg: 'bg-blue-800',
    buttonHoverBg: 'hover:bg-blue-900',
    music: '/music/sad-theme.mp3'
  },
  inLove: {
    bgGradient: 'from-pink-200 to-red-200',
    textColor: 'text-gray-800',
    cardBg: 'bg-pink-100',
    buttonBg: 'bg-pink-400',
    buttonHoverBg: 'hover:bg-pink-500',
    music: '/music/in-love-theme.mp3'
  },
  happy: {
    bgGradient: 'from-yellow-200 to-orange-200',
    textColor: 'text-gray-800',
    cardBg: 'bg-yellow-100',
    buttonBg: 'bg-orange-300',
    buttonHoverBg: 'hover:bg-orange-400',
    music: '/music/happy-theme.mp3'
  }
}

const RainDrop = ({ delay }) => (
  <motion.div
    className="absolute bg-blue-400 rounded-full w-0.5 h-4"
    style={{ left: `${Math.random() * 100}%`, top: `-${Math.random() * 20}%` }}
    animate={{
      y: ['0%', '120%'],
      opacity: [0, 1, 0]
    }}
    transition={{
      duration: 1,
      repeat: Infinity,
      delay: delay
    }}
  />
)

const Heart = ({ delay }) => (
  <motion.div
    className="absolute text-pink-500 text-2xl"
    style={{ left: `${Math.random() * 100}%`, top: `-${Math.random() * 20}%` }}
    initial={{ scale: 0 }}
    animate={{
      y: ['0%', '120%'],
      scale: [0, 1, 0],
      rotate: [0, 45, 0]
    }}
    transition={{
      duration: 3,
      repeat: Infinity,
      delay: delay
    }}
  >
    ❤️
  </motion.div>
)

const Smiley = ({ delay }) => (
  <motion.div
    className="absolute text-yellow-500 text-2xl"
    style={{ left: `${Math.random() * 100}%`, top: `-${Math.random() * 20}%` }}
    initial={{ scale: 0 }}
    animate={{
      y: ['0%', '120%'],
      scale: [0, 1, 0],
      rotate: [0, 360, 0]
    }}
    transition={{
      duration: 4,
      repeat: Infinity,
      delay: delay
    }}
  >
    😊
  </motion.div>
)

export default function AboutPage() {
  const [activeTab, setActiveTab] = useState('info')
  const [stars, setStars] = useState([])
  const [currentTheme, setCurrentTheme] = useState('default')
  const [isDarkMode, setIsDarkMode] = useState(true)
  const audioRef = useRef(null)

  useEffect(() => {
    setStars(Array.from({ length: 100 }, () => ({
      x: Math.random() * 100,
      y: Math.random() * 100,
      size: Math.random() * 3 + 1,
    })))
  }, [])

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.src = themeConfig[currentTheme].music
      audioRef.current.play().catch(error => console.error("Audio playback failed", error))
    }
  }, [currentTheme])

  const tabs = {
    info: { icon: User, label: '𝑼𝒔𝒆𝒓 𝑰𝒏𝒇𝒐' },
    skills: { icon: Code, label: '𝑺𝒌𝒊𝒍𝒍𝒔' },
    teams: { icon: Briefcase, label: '𝑻𝒆𝒂𝒎𝒔' },
  }

  const skills = [
    { name: '𝑷𝒚𝒕𝒉𝒐𝒏', level: 5 },
    { name: '𝑷𝑯𝑷', level: 4 },
    { name: '𝑪++', level: 3 },
  ]

  const teams = ['MΛƧƬΣЯƧ ᴸᶜ ᵀᵉᵃᵐ', '𝓘𝓷𝓯𝓲𝓷𝓲𝓽𝔂']

  const changeTheme = () => {
    const themes = ['default', 'sad', 'inLove', 'happy']
    const currentIndex = themes.indexOf(currentTheme)
    const nextIndex = (currentIndex + 1) % themes.length
    setCurrentTheme(themes[nextIndex])
  }

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode)
  }

  return (
    <div className={`min-h-screen bg-gradient-to-b ${isDarkMode ? themeConfig[currentTheme].bgGradient : 'from-gray-100 to-white'} ${isDarkMode ? themeConfig[currentTheme].textColor : 'text-gray-800'} p-4 relative overflow-hidden transition-colors duration-500`}>
      <audio ref={audioRef} loop autoPlay />
      <AnimatePresence>
        {stars.map((star, index) => (
          <motion.div
            key={index}
            className={`absolute rounded-full ${isDarkMode ? 'bg-white' : 'bg-gray-800'}`}
            style={{
              left: `${star.x}%`,
              top: `${star.y}%`,
              width: `${star.size}px`,
              height: `${star.size}px`,
            }}
            initial={{ opacity: 0, scale: 0 }}
            animate={{
              opacity: [0, 1, 0],
              scale: [1, 1.5, 1],
            }}
            exit={{ opacity: 0, scale: 0 }}
            transition={{
              duration: Math.random() * 3 + 2,
              repeat: Infinity,
              repeatType: 'reverse',
            }}
          />
        ))}
      </AnimatePresence>

      {currentTheme === 'sad' && Array.from({ length: 50 }).map((_, index) => (
        <RainDrop key={index} delay={Math.random() * 2} />
      ))}

      {currentTheme === 'inLove' && Array.from({ length: 20 }).map((_, index) => (
        <Heart key={index} delay={Math.random() * 3} />
      ))}

      {currentTheme === 'happy' && Array.from({ length: 20 }).map((_, index) => (
        <Smiley key={index} delay={Math.random() * 4} />
      ))}

      <motion.div
        className={`max-w-2xl mx-auto ${isDarkMode ? themeConfig[currentTheme].cardBg : 'bg-white'} rounded-lg shadow-xl overflow-hidden transition-colors duration-500`}
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="relative h-48 overflow-hidden">
          <img
            src="/images/photo_2023-05-19_09-30-40.jpg"
            alt="Profile background"
            className="w-full h-full object-cover"
          />
          <motion.img
            src="/images/photo_2024-04-14_11-16-08.jpg"
            alt="Profile picture"
            className="absolute left-1/2 bottom-0 transform -translate-x-1/2 w-32 h-32 rounded-full border-4 border-gray-800"
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: -64, opacity: 1 }}
            transition={{ type: 'spring', stiffness: 50, damping: 20, delay: 0.5 }}
          />
        </div>

        <div className="pt-20 pb-8 px-8 text-center">
          <motion.h1
            className="text-4xl font-bold mb-2"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
          >
            𝑨𝑳𝑬𝑿
          </motion.h1>
          <motion.p
            className={`${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1 }}
          >
            {['𝑷𝒂𝒔𝒔𝒊𝒐𝒏𝒂𝒕𝒆 𝒅𝒆𝒗𝒆𝒍𝒐𝒑𝒆𝒓 𝑱𝑹', '𝑪𝒉𝒆𝒂𝒕𝒊𝒏𝒈 𝒕𝒐 𝒍𝒆𝒂𝒓𝒏 𝒏𝒆𝒘 𝒕𝒉𝒊𝒏𝒈𝒔', '18 𝒚.𝒐, 𝑽𝒊𝒄𝒕𝒐𝒓❜𝒔 𝑩𝒓𝒐𝒕𝒉𝒆𝒓'].map((text, index) => (
              <motion.span
                key={index}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 1 + index * 0.5, duration: 0.5 }}
              >
                {text}
                {index < 2 && <br />}
              </motion.span>
            ))}
          </motion.p>

          <div className="flex justify-center space-x-4 mb-8">
            {Object.entries(tabs).map(([key, { icon: Icon, label }], index) => (
              <motion.button
                key={key}
                className={`flex items-center space-x-2 px-4 py-2 rounded-full ${
                  activeTab === key ? (isDarkMode ? themeConfig[currentTheme].buttonBg : 'bg-gray-200') : (isDarkMode ? 'bg-gray-700' : 'bg-gray-100')
                } ${isDarkMode ? themeConfig[currentTheme].buttonHoverBg : 'hover:bg-gray-300'}`}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setActiveTab(key)}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 + index * 0.1 }}
              >
                <Icon className="w-5 h-5" />
                <span>{label}</span>
              </motion.button>
            ))}
          </div>

          <AnimatePresence mode="wait">
            <motion.div
              key={activeTab}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              {activeTab === 'info' && (
                <div>
                  <p className="text-lg mb-2">𝑰𝑫: [5828803724]</p>
                  <p className={`${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                  </p>
                </div>
              )}
              {activeTab === 'skills' && (
                <div className="flex flex-wrap justify-center gap-8">
                  {skills.map((skill, index) => (
                    <motion.div
                      key={skill.name}
                      className="flex flex-col items-center"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.1 }}
                    >
                      <p className="font-semibold mb-2">{skill.name}</p>
                      <div className="flex">
                        {[...Array(5)].map((_, i) => (
                          <Star
                            key={i}
                            className={`w-5 h-5 ${i < skill.level ? 'text-yellow-400 fill-current' : (isDarkMode ? 'text-gray-600' : 'text-gray-300')}`}
                          />
                        ))}
                      </div>
                    </motion.div>
                  ))}
                </div>
              )}
              {activeTab === 'teams' && (
                <div>
                  {teams.map((team, index) => (
                    <motion.p
                      key={team}
                      className="text-lg mb-2"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.1 }}
                    >
                      {team}
                    </motion.p>
                  ))}
                </div>
              )}
            </motion.div>
          </AnimatePresence>
        </div>

        <div className={`${isDarkMode ? themeConfig[currentTheme].cardBg : 'bg-gray-100'} px-8 py-4 flex justify-center space-x-4 transition-colors duration-500`}>
          <motion.a
            href="https://t.me/understaker3am"
            target="_blank"
            rel="noopener noreferrer"
            className={`${isDarkMode ? themeConfig[currentTheme].buttonBg : 'bg-blue-500'} ${isDarkMode ? themeConfig[currentTheme].buttonHoverBg : 'hover:bg-blue-600'} text-white px-4 py-2 rounded-full transition duration-300 flex items-center space-x-2`}

            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <MessageCircle className="w-5 h-5" />
            <span>𝑻𝒆𝒍𝒆𝒈𝒓𝒂𝒎</span>
          </motion.a>
          <motion.a
            href="https://github.com/Understaker3am"
            target="_blank"
            rel="noopener noreferrer"
            className={`${isDarkMode ? themeConfig[currentTheme].buttonBg : 'bg-gray-800'} ${isDarkMode ? themeConfig[currentTheme].buttonHoverBg : 'hover:bg-gray-900'} text-white px-4 py-2 rounded-full transition duration-300 flex items-center space-x-2`}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Github className="w-5 h-5" />
            <span>𝑮𝒊𝒕𝒉𝒖𝒃</span>
          </motion.a>
        </div>
      </motion.div>

      <motion.button
        className={`fixed bottom-4 right-4 ${isDarkMode ? themeConfig[currentTheme].buttonBg : 'bg-gray-200'} ${isDarkMode ? themeConfig[currentTheme].buttonHoverBg : 'hover:bg-gray-300'} ${isDarkMode ? 'text-white' : 'text-gray-800'} p-3 rounded-full shadow-lg`}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={changeTheme}
      >
        <Music className="w-6 h-6" />
      </motion.button>

      <motion.button
        className={`fixed bottom-4 left-4 ${isDarkMode ? themeConfig[currentTheme].buttonBg : 'bg-gray-200'} ${isDarkMode ? themeConfig[currentTheme].buttonHoverBg : 'hover:bg-gray-300'} ${isDarkMode ? 'text-white' : 'text-gray-800'} p-3 rounded-full shadow-lg`}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={toggleDarkMode}
      >
        {isDarkMode ? <Sun className="w-6 h-6" /> : <Moon className="w-6 h-6" />}
      </motion.button>
    </div>
  )
}